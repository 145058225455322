import React, { useEffect } from 'react';

import SEO from "../components/seo"
import { WritingObserver } from "../components/animations/writing";
import BackgroundImage from 'gatsby-background-image';



const MentionsPage = ({ data }) => {

  //   useEffect(() => {
  //     const windowGlobal = typeof window !== 'undefined' && window    
  //   });

  return (
    <>
      <SEO title="mentions" />
      <div className="flex relative z-30 items-center justify-center flex-col w-screen pt-40 pb-20">
        <div className="flex w-10/12 md:w-6/12 text-left items-center justify-center flex-col">
          <p className="font-Gilroy_medium leading-tight text-xs md:text-base xl:text-base break-normal">
            Conformément aux dispositions des articles 6-III et 19 de la Loi n°2044-575- du 21 juin 2004 pour la confiance dans l’économie numérique, il est porté à la connaissance des utilisateurs et des visiteurs du site AGENCE TACTILE les mentions légales ci-dessous : Le site AGENCE TACTILE est accessible à l’adresse suivante : www.agence-tactile.fr (ci après Le Site).
L’accès et l’utilisation du Site sont soumis aux présentes mentions légales détaillées ci-après ainsi qu’aux lois et/ou règlements applicables. La connexion, l’utilisation et l’accès à ce Site impliquent l’acceptation intégrale et sans réserve de l’internaute de toutes les dispositions des présentes mentions légales.
<br></br>
<br></br>
<b>ARTICLE 1 – INFORMATIONS LÉGALES</b>
<br></br>
Le Site AGENCE TACTILE est édité par : AGENCE TACTILE
<br></br>
ayant son siège social à l’adresse suivante : 20 rue de l'Hotel de Ville 92200 Neuilly sur Seine 
<br></br>et immatriculée au numéro suivant : Siret 51319339100028
<br></br>
adresse mail : <a href="mailto:contact@agence-tactile.fr" className="underline cursor-pointer" rel="noopener noreferrer" target="_blank">contact@agence-tactile.fr</a>
<br></br>
ci-après « l’Éditeur » La directrice de la publication est Delphine Descorps
<br></br>
Adresse mail de contact : <a href="mailto:contact@agence-tactile.fr" className="underline cursor-pointer" rel="noopener noreferrer" target="_blank">contact@agence-tactile.fr</a> ci-après « la directrice de publication »
<br></br>
Conception, réalisation : Tactile
<br></br>
Dont le siège est situé : 20 rue de l’Hôtel de Ville à Neuilly sur Seine (92200)
<br></br>
téléphone : 01 84 20 07 03
<br></br>
<a href="https://www.agence-tactile.fr" className="underline cursor-pointer" rel="noopener noreferrer" target="_blank">www.agence-tactile.fr</a>
<br></br>
<br></br>
<b>ARTICLE 2 – ACCESSIBILITÉ</b>
<br></br>
Le Site est par principe accessible aux utilisateurs 24/24h et 7/7j, sauf interruption programmée ou non, pour des besoins de maintenance ou en cas de force majeure. En cas d’impossibilité d’accès au Site, celui-ci s’engage à faire son maximum afin d’en rétablir l’accès. Le Site ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant de son indisponibilité.
<br></br>
<br></br>
<b>ARTICLE 3 – COLLECTE DES DONNÉES ET LOI INFORMATIQUE ET LIBERTÉS</b>
<br></br>
Ce Site est conforme aux dispositions de la Loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés. Ainsi, l’Utilisateur bénéficie notamment d’un droit d’opposition (articles 32 et 38), d’accès (articles 38 et 39) et de rectification des données le concernant (article 40). Pour faire usage de ce droit, l’Utilisateur doit s’adresser à l’Éditeur en le contactant par mail à l’adresse suivante : <a href="mailto:contact@agence-tactile.fr" className="underline cursor-pointer" rel="noopener noreferrer" target="_blank">contact@agence-tactile.fr</a> ou, par courrier adressé au siège social de l’Éditeur en précisant ses coordonnées (nom, prénom, adresse, mail).
<br></br>
<br></br>
<b>ARTICLE 4 – POLITIQUE DE COOKIES</b>
<br></br>
Le Site AGENCE TACTILE a éventuellement recours aux techniques de « cookies » lui permettant de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d’améliorer le service pour le confort de l’Utilisateur. Conformément à la législation européenne, le Site AGENCE TACTILE a mis à jour sa politique de confidentialité en matière de cookies. L’Utilisateur est libre d’accepter ou de refuser les cookies de tous sites internet en modifiant les paramètres de son navigateur Internet.
<br></br>
<br></br>
<b>ARTICLE 5 – LOI APPLICABLE ET JURIDICTION</b>
<br></br>
Tout litige en relation avec l’utilisation du Site <a href="https://www.agence-tactile.fr" className="underline cursor-pointer" rel="noopener noreferrer" target="_blank">www.agence-tactile.fr</a> est soumis au droit français.
<br></br>
<br></br>
<b>ARTICLE 6 – COLLECTE ET TRAITEMENT DES DONNÉES</b>
<br></br>
AGENCE TACTILE s’engage à ce que la collecte et le traitement de vos données, effectués à partir du site <a href="https://www.agence-tactile.fr" className="underline cursor-pointer" rel="noopener noreferrer" target="_blank">www.agence-tactile.fr</a> soient conformes au Règlement Général de la Protection des Données (RGPD) et à la loi « Informatique et Libertés ».
Les informations recueillies dans le cadre du formulaire de contact sont uniquement transmises par mail au standard de AGENCE TACTILE afin d’apporter une réponse aux informations que vous demandez. Ces données ne sont pas conservées par AGENCE TACTILE et n’alimentent aucune base de données.
<br></br>
<br></br>
<b>ARTICLE 7 – DROITS ET LIBERTÉS RELATIFS AU TRAITEMENT DES DONNÉES</b>
<br></br>
Conformément aux dispositions de la loi « Informatique et Libertés » susvisées, toute personne ayant laissé ses coordonnées ou autre information nominative sur le site dispose d’un droit d’accès, de rectification, d’information ou de suppression des données nominatives qui la concernent.
Pour toute information ou exercice de vos droits Informatique et Libertés, vous pouvez contacter le délégué à la protection des données (DPO) de AGENCE TACTILE : 20 rue de l'Hotel de Ville 92200 Neuilly sur Seine
          </p>
        </div>
      </div>
      <div className="fixed top-0 left-0 h-screen w-screen">
      <div className="absolute z-20 h-40 w-60 top-0 right-0 mr-40">
        <BackgroundImage
          Tag="section"
          className="w-full h-full top-0 z-0 absolute"
          style={{ backgroundPosition: "50% 50%" }}
          fluid={data.paper1.childImageSharp.fluid}
          alt="forme"
          backgroundColor={`#FFF`}
        />
      </div>
      <div className="absolute z-20 w-40 h-20 md:h-40 md:w-90 bottom-0 right-0">
        <BackgroundImage
          Tag="section"
          className="w-full h-full top-0 z-0 absolute"
          style={{ backgroundPosition: "50% 50%" }}
          fluid={data.Formbottom.childImageSharp.fluid}
          alt="forme"
          backgroundColor={`#FFF`}
        />
      </div>
      <div className="absolute z-20 mt-24 md:ml-40 w-20 md:h-32 w-30 md:w-70 top-0 left-0">
        <BackgroundImage
          Tag="section"
          className="w-full h-full top-0 z-0 absolute"
          style={{ backgroundPosition: "50% 50%" }}
          fluid={data.Formtop.childImageSharp.fluid}
          alt="forme"
          backgroundColor={`#FFF`}
        />
      </div>
      </div>
      <div className="h-screen w-screen bg-white fixed z-10"></div>
    </>
  )
}

export const mentionsPageQuery = graphql`
  query MentionsPage {
    Formbottom: file(relativePath: { eq: "mentions/form-bottom.jpg" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 474) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
     },
     Formtop: file(relativePath: { eq: "mentions/form-top.jpg" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
     },
     paper1: file(relativePath: { eq: "mentions/paper1.jpg" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 308) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
     },
}
`

export default MentionsPage